import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
  ReferenceLine,
} from "recharts";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandshake,
  faChartLine,
  faGaugeHigh,
  faClipboardCheck,
  faFaceSmile,
  faChartPie,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";

const CATEGORY_ICONS = {
  SERVICE_DELIVERY: faHandshake,
  PRODUCTIVITY_COST: faChartLine,
  EFFICIENCY_SPEED: faGaugeHigh,
  QUALITY_COMPLIANCE: faClipboardCheck,
  CUSTOMER_EXPERIENCE: faFaceSmile,
};

const DashboardContainer = styled.div`
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f5f5f5;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
`;

const CardTitle = styled.h2`
  color: #0f518b;
  border-bottom: 2px solid #0f518b;
  padding-bottom: 10px;
  margin-top: 0;
`;

const SectionTitle = styled.h3`
  color: #0f518b;
  margin-top: 20px;
  margin-bottom: 15px;
`;

const UpdateList = styled.ul`
  padding-left: 20px;
  li {
    margin-bottom: 10px;
  }
`;

// Removed MetricCard as it's no longer used

// Removed MetricTitle as it's no longer used

const MetricValue = styled.span`
  padding: 5px 10px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  background-color: ${(props) => props.color};
`;

const TeamStats = styled.div`
  background-color: #f0f8ff;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 20px;
`;

// Removed PerformanceChartsGrid as it's no longer used

// Removed unused ThemedColumn component

const ColumnTitle = styled.h3`
  color: white;
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;
`;

const ColumnSubtitle = styled.p`
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
`;

const MetricRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 10px;
  position: relative;
  
  &.clickable {
    cursor: pointer;
    transition: background-color 0.2s ease;
    
    &:hover {
      background-color: #f5f5f5;
    }
    
    &::after {
      content: "▼";
      position: absolute;
      right: 40px;
      font-size: 12px;
      color: #666;
      transition: transform 0.2s ease;
    }
    
    &.expanded::after {
      transform: rotate(180deg);
    }
  }
`;

const MetricLabel = styled.span`
  font-weight: bold;
`;

const IndicatorCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  border: 1px solid #888888;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

// Removed PerformanceChart as it's no longer used

// Removed ChartHeader as it's no longer used

// Removed ChartAvatar as it's no longer used

// Removed ChartTitle as it's no longer used

const ActionTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  th,
  td {
    border: 1px solid #e0e0e0;
    padding: 12px;
    text-align: left;
  }
  th {
    background-color: #f0f8ff;
    font-weight: bold;
    color: #0f518b;
  }
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const Button = styled.button`
  padding: 8px 12px;
  background-color: #0f518b;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #0a3d6a;
  }
`;

const AddActionButton = styled(Button)`
  margin-top: 15px;
`;

const IndividualPerformance = styled.details`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  summary {
    font-weight: bold;
    cursor: pointer;
    color: #0f518b;
    &:hover {
      text-decoration: underline;
    }
  }
`;

// Removed MetricsGroup as it's no longer used

// Removed MetricsGroupTitle as it's no longer used

// Removed MetricsGroupContent as it's no longer used

// Removed ChartWrapper as it's no longer used

const UpdateColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const UpdateColumn = styled.div`
  h4 {
    color: #0f518b;
    margin-bottom: 10px;
  }
`;

const IndividualPerformanceTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 15px;
`;

const TableHeader = styled.th`
  background-color: #f0f8ff;
  font-weight: bold;
  color: #0f518b;
  padding: 10px;
  text-align: left;
  border: 1px solid #e0e0e0;
`;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #e0e0e0;
`;

const SmallPrint = styled.small`
  display: block;
  color: #666;
  font-size: 0.8em;
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Tab = styled.div`
  padding: 10px 20px;
  background-color: ${(props) => (props.active ? "#0f518b" : "#f0f8ff")};
  color: ${(props) => (props.active ? "white" : "#0f518b")};
  border-radius: 8px 8px 0 0;
  margin-right: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
  border: 1px solid #e0e0e0;
  border-bottom: ${(props) => (props.active ? "none" : "1px solid #e0e0e0")};

  &:hover {
    background-color: ${(props) => (props.active ? "#0f518b" : "#e6f2ff")};
  }
`;

const ViewContainer = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
`;

const StageColumn = styled.div`
  background-color: #7b9aa6;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StageTitle = styled.h3`
  margin: 0 0 10px 0;
  font-size: 18px;
`;

const StageMetrics = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
`;

const MetricBar = styled.div`
  background-color: ${props => props.backgroundColor || "#E3F2FD"};
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0;
  position: relative;
  display: flex;
  align-items: center;
`;

const MetricBarTitle = styled.div`
  font-weight: bold;
  margin-right: 10px;
  width: 200px;
`;

const MetricBarSegment = styled.div`
  flex: 1;
  text-align: center;
  position: relative;
  padding: 0 10px;
  border-right: 1px dashed #ccc;
  &:last-child {
    border-right: none;
  }
`;

const WarningTriangle = styled.div`
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #F44336;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
`;

const Dashboard = () => {
  const [activeView, setActiveView] = useState("bigPicture");
  const [showTurnAroundTimeChart, setShowTurnAroundTimeChart] = useState(false);
  const [showReworkRateChart, setShowReworkRateChart] = useState(false);
  const [showTeamTurnAroundTimeChart, setShowTeamTurnAroundTimeChart] = useState(false);
  const [showTeamProductivityChart, setShowTeamProductivityChart] = useState(false);
  const [showTeamComplianceRateChart, setShowTeamComplianceRateChart] = useState(false);
  const [showTeamEscalatedComplaintsChart, setShowTeamEscalatedComplaintsChart] = useState(false);
  const [actions, setActions] = useState([
    {
      description: "Cross skill team A to reduce WIP in queue B",
      dateAssigned: "9 Aug",
      teamMember: "Noura",
      relatedMetric: CATEGORY_ICONS.SERVICE_DELIVERY,
    },
    {
      description: "Follow up on the feature to auto-tag high-priority cases",
      dateAssigned: "6 Aug",
      teamMember: "JohnH",
      relatedMetric: CATEGORY_ICONS.QUALITY_COMPLIANCE,
    },
    {
      description: "Train the team on priority tagging",
      dateAssigned: "2 Jul",
      teamMember: "Sarah",
      relatedMetric: CATEGORY_ICONS.QUALITY_COMPLIANCE,
    },
    {
      description: "Implement new customer feedback system",
      dateAssigned: "12 Aug",
      teamMember: "Alex",
      relatedMetric: CATEGORY_ICONS.CUSTOMER_EXPERIENCE,
    },
    {
      description:
        "Review and optimize refund processing workflow onboarding training",
      dateAssigned: "15 Aug",
      teamMember: "Emma",
      relatedMetric: CATEGORY_ICONS.PRODUCTIVITY_COST,
    },
  ]);

  const valueChainStages = [
    {
      name: "Indexing",
      staffCount: 3.5,
      documentCount: 13,
      timeMinutes: 5
    },
    {
      name: "Triage",
      staffCount: 9,
      documentCount: 134,
      timeMinutes: 15
    },
    {
      name: "Assessment",
      staffCount: 43,
      documentCount: 415,
      timeMinutes: 45
    },
    {
      name: "Documentation",
      staffCount: 12,
      documentCount: 21,
      timeMinutes: 30
    },
    {
      name: "Fulfilment",
      staffCount: 5,
      documentCount: 7,
      timeMinutes: 10
    }
  ];

  const turnAroundTimeData = {
    total: 5.2,
    stages: [0.1, 1.1, 3, 0.8, 0.2],
    warnings: [false, true, true, false, false]
  };

  const reworkRateData = {
    total: 49,
    stages: [4, 35, 22, 12, 3],
    warnings: [false, true, true, false, false]
  };

  const costToServeData = {
    total: 19.5
  };

  const individualPerformanceData = [
    {
      name: "Noura",
      productivityYesterday: 85,
      productivityWeek: 92,
      throughput: 7.5,
      quality: 95,
    },
    {
      name: "JohnH",
      productivityYesterday: 78,
      productivityWeek: 88,
      throughput: 8.2,
      quality: 97,
    },
    {
      name: "Sarah",
      productivityYesterday: 92,
      productivityWeek: 90,
      throughput: 6.8,
      quality: 93,
    },
    {
      name: "Alex",
      productivityYesterday: 91,
      productivityWeek: 60,
      throughput: 6.0,
      quality: 98,
    },
    {
      name: "Emma",
      productivityYesterday: 81,
      productivityWeek: 60,
      throughput: 6.5,
      quality: 92,
    },
    {
      name: "JohnB",
      productivityYesterday: 91,
      productivityWeek: 70,
      throughput: 6.1,
      quality: 82,
    },
  ];

  const addAction = () => {
    setActions([
      ...actions,
      { description: "", dateAssigned: "", teamMember: "", relatedMetric: "" },
    ]);
  };

  const getColorForMetric = (value, threshold) => {
    if (value >= threshold.high) return "#4CAF50";
    if (value >= threshold.low) return "#FFC107";
    return "#F44336";
  };

  // Removed CustomizedAxisTick as it's no longer used

  // Removed MetricCardComponent as it's no longer used

  // Removed PerformanceChartComponent as it's no longer used

  const renderTabs = () => {
    return (
      <TabContainer>
        <Tab
          active={activeView === "bigPicture"}
          onClick={() => setActiveView("bigPicture")}
        >
          <FontAwesomeIcon icon={faChartPie} /> Big Picture
        </Tab>
        <Tab
          active={activeView === "e2ePerformance"}
          onClick={() => setActiveView("e2ePerformance")}
        >
          <FontAwesomeIcon icon={faUserTie} /> Manager View - E2E Performance
          Metrics
        </Tab>
        <Tab
          active={activeView === "e2eValueChain"}
          onClick={() => setActiveView("e2eValueChain")}
        >
          <FontAwesomeIcon icon={faUserTie} /> Manager View - E2E Value Chain
        </Tab>
      </TabContainer>
    );
  };

  return (
    <DashboardContainer>
      {renderTabs()}

      {/* Big Picture View */}
      <ViewContainer active={activeView === "bigPicture"}>
        <Card>
          <CardTitle>
            Team BRH-M (
            {new Date().toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}
            )
          </CardTitle>
          <p
            style={{
              fontSize: "1.2em",
              fontStyle: "italic",
              marginTop: "10px",
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            "Supporting Every Journey, Finding Every Solution"
          </p>
          <UpdateColumns>
            <UpdateColumn>
              <h4>Successes</h4>
              <UpdateList>
                <li>Well done James, coaching new team members</li>
                <li>Congrats John for going above &amp; beyond in xx case</li>
              </UpdateList>
            </UpdateColumn>
            <UpdateColumn>
              <h4>Comms</h4>
              <UpdateList>
                <li>Don't forget to book annual leave</li>
                <li>Culture survey coming in two weeks</li>
              </UpdateList>
            </UpdateColumn>
          </UpdateColumns>
        </Card>

        <Card>
          <CardTitle>Performance</CardTitle>
          <div>
            <SectionTitle>Our Team</SectionTitle>
            <TeamStats>
              <p>
                <strong>Case Volume (yesterday):</strong> 109
              </p>
              <p>
                <strong>FTE (yesterday):</strong> 12
              </p>
            </TeamStats>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "20px" }}>
              {/* Delivery Column - Blue Theme */}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ backgroundColor: "#4285F4", borderRadius: "8px 8px 0 0", padding: "15px", color: "white" }}>
                  <ColumnTitle>Delivery</ColumnTitle>
                  <ColumnSubtitle>February 2025</ColumnSubtitle>
                </div>
                <div style={{ backgroundColor: "#E3F2FD", borderRadius: "0 0 8px 8px", padding: "15px" }}>
                  <MetricRow style={{ backgroundColor: "white", position: "relative" }}>
                    <MetricLabel>Volume out</MetricLabel>
                    <div style={{ paddingRight: "40px" }}>2390</div>
                    <IndicatorCircle color="white" />
                  </MetricRow>
                  
                  <MetricRow style={{ backgroundColor: "white", position: "relative" }}>
                    <MetricLabel>Customer Service Level</MetricLabel>
                    <div style={{ paddingRight: "40px" }}>87%</div>
                    <IndicatorCircle color="#F44336" />
                  </MetricRow>
                  
                  <MetricRow style={{ backgroundColor: "white", position: "relative" }}>
                    <MetricLabel>Turn Around Time</MetricLabel>
                    <div style={{ paddingRight: "40px" }}>5.2 Days</div>
                    <IndicatorCircle color="#F44336" />
                  </MetricRow>
                </div>
              </div>
              
              {/* Cost Column - Purple Theme */}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ backgroundColor: "#A142F4", borderRadius: "8px 8px 0 0", padding: "15px", color: "white" }}>
                  <ColumnTitle>Cost</ColumnTitle>
                  <ColumnSubtitle>February 2025</ColumnSubtitle>
                </div>
                <div style={{ backgroundColor: "#F3E5F5", borderRadius: "0 0 8px 8px", padding: "15px" }}>
                  <MetricRow style={{ backgroundColor: "white", position: "relative" }}>
                    <MetricLabel>Cost to Serve</MetricLabel>
                    <div style={{ paddingRight: "40px" }}>$19.5</div>
                    <IndicatorCircle color="#4CAF50" />
                  </MetricRow>
                  
                  <MetricRow style={{ backgroundColor: "white", position: "relative" }}>
                    <MetricLabel>Rework rate</MetricLabel>
                    <div style={{ paddingRight: "40px" }}>49%</div>
                    <IndicatorCircle color="#F44336" />
                  </MetricRow>
                  
                  <MetricRow style={{ backgroundColor: "white", position: "relative" }}>
                    <MetricLabel>Throughput</MetricLabel>
                    <div style={{ paddingRight: "40px" }}>5.2</div>
                    <IndicatorCircle color="#4CAF50" />
                  </MetricRow>
                </div>
              </div>
              
              {/* Compliance Column - Orange Theme */}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ backgroundColor: "#FF9800", borderRadius: "8px 8px 0 0", padding: "15px", color: "white" }}>
                  <ColumnTitle>Compliance</ColumnTitle>
                  <ColumnSubtitle>February 2025</ColumnSubtitle>
                </div>
                <div style={{ backgroundColor: "#FFF3E0", borderRadius: "0 0 8px 8px", padding: "15px" }}>
                  <MetricRow style={{ backgroundColor: "white", position: "relative" }}>
                    <MetricLabel>Compliance Rate</MetricLabel>
                    <div style={{ paddingRight: "40px" }}>98.1%</div>
                    <IndicatorCircle color="#FFC107" />
                  </MetricRow>
                  
                  <MetricRow style={{ backgroundColor: "white", position: "relative" }}>
                    <MetricLabel>QA Pass rate</MetricLabel>
                    <div style={{ paddingRight: "40px" }}>73%</div>
                    <IndicatorCircle color="#F44336" />
                  </MetricRow>
                </div>
              </div>
              
              {/* Experience Column - Yellow Theme */}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ backgroundColor: "#FFEB3B", borderRadius: "8px 8px 0 0", padding: "15px", color: "white" }}>
                  <ColumnTitle>Experience</ColumnTitle>
                  <ColumnSubtitle>February 2025</ColumnSubtitle>
                </div>
                <div style={{ backgroundColor: "#FFFDE7", borderRadius: "0 0 8px 8px", padding: "15px" }}>
                  <MetricRow style={{ backgroundColor: "white", position: "relative" }}>
                    <MetricLabel>Escalated Complaints</MetricLabel>
                    <div style={{ paddingRight: "40px" }}>27%</div>
                    <IndicatorCircle color="#F44336" />
                  </MetricRow>
                  
                  <MetricRow style={{ backgroundColor: "white", position: "relative" }}>
                    <MetricLabel>NPS</MetricLabel>
                    <div style={{ paddingRight: "40px" }}>###%</div>
                    <IndicatorCircle color="white" />
                  </MetricRow>
                </div>
              </div>
            </div>
          </div>
        </Card>

        <Card>
          <CardTitle>Our Team Performance</CardTitle>
          <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "20px" }}>
            {/* Delivery Column - Blue Theme */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ backgroundColor: "#4285F4", borderRadius: "8px 8px 0 0", padding: "15px", color: "white" }}>
                <ColumnTitle>Delivery</ColumnTitle>
                <ColumnSubtitle>Yesterday</ColumnSubtitle>
              </div>
              <div style={{ backgroundColor: "#E3F2FD", borderRadius: "0 0 8px 8px", padding: "15px" }}>
                <MetricRow style={{ backgroundColor: "white", position: "relative" }}>
                  <MetricLabel>Volume out</MetricLabel>
                  <div style={{ paddingRight: "40px" }}>48</div>
                  <IndicatorCircle color="white" />
                </MetricRow>
                
                <MetricRow style={{ backgroundColor: "white", position: "relative" }}>
                  <MetricLabel>WIP</MetricLabel>
                  <div style={{ paddingRight: "40px" }}>230</div>
                  <IndicatorCircle color="#F44336" />
                </MetricRow>
                
                <div style={{ marginTop: "10px" }}>
                  <ColumnSubtitle style={{ marginBottom: "10px" }}>Last Week</ColumnSubtitle>
                  <MetricRow 
                    className={`clickable ${showTeamTurnAroundTimeChart ? 'expanded' : ''}`}
                    style={{ backgroundColor: "white", position: "relative" }}
                    onClick={() => setShowTeamTurnAroundTimeChart(!showTeamTurnAroundTimeChart)}
                  >
                    <MetricLabel>Turn Around Time</MetricLabel>
                    <div style={{ paddingRight: "40px" }}>5.0 Days</div>
                    <IndicatorCircle color="#F44336" />
                  </MetricRow>
                  
                  {/* Turn Around Time Chart - Conditionally rendered */}
                  <div 
                    style={{ 
                      marginTop: "15px", 
                      backgroundColor: "white", 
                      padding: showTeamTurnAroundTimeChart ? "10px" : "0", 
                      borderRadius: "6px",
                      transition: "all 0.3s ease",
                      maxHeight: showTeamTurnAroundTimeChart ? "200px" : "0",
                      overflow: "hidden",
                      opacity: showTeamTurnAroundTimeChart ? "1" : "0",
                      visibility: showTeamTurnAroundTimeChart ? "visible" : "hidden"
                    }}
                  >
                    <ResponsiveContainer width="100%" height={150}>
                      <BarChart
                        data={[
                          { week: "7", value: 4.7 },
                          { week: "8", value: 5.0 },
                          { week: "9", value: 6.2 },
                          { week: "10", value: 5.2 },
                          { week: "11", value: 5.0 }
                        ]}
                        margin={{ top: 20, right: 10, left: 10, bottom: 20 }}
                      >
                        <XAxis dataKey="week" tick={{ fontSize: 10 }} />
                        <YAxis 
                          domain={[4, 7]} 
                          tick={{ fontSize: 10 }} 
                          label={{ value: 'Days', angle: -90, position: 'insideLeft', style: { fontSize: 10, textAnchor: 'middle' } }}
                        />
                        <Tooltip />
                        <ReferenceLine y={3.0} stroke="#333" strokeWidth={1} />
                        <Bar dataKey="value" fill="#4CAF50">
                          {[
                            { week: "7", value: 4.7 },
                            { week: "8", value: 5.0 },
                            { week: "9", value: 6.2 },
                            { week: "10", value: 5.2 },
                            { week: "11", value: 5.0 }
                          ].map((entry, index) => (
                            <Cell 
                              key={`cell-${index}`} 
                              fill={entry.value > 3.0 ? "#F44336" : "#4CAF50"} 
                            />
                          ))}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                    <div style={{ textAlign: "center", fontSize: "12px", marginTop: "5px" }}>Week</div>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Cost Column - Purple Theme */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ backgroundColor: "#A142F4", borderRadius: "8px 8px 0 0", padding: "15px", color: "white" }}>
                <ColumnTitle>Cost</ColumnTitle>
                <ColumnSubtitle>Last Week</ColumnSubtitle>
              </div>
              <div style={{ backgroundColor: "#F3E5F5", borderRadius: "0 0 8px 8px", padding: "15px" }}>
                <MetricRow 
                  className={`clickable ${showTeamProductivityChart ? 'expanded' : ''}`}
                  style={{ backgroundColor: "white", position: "relative" }}
                  onClick={() => setShowTeamProductivityChart(!showTeamProductivityChart)}
                >
                  <MetricLabel>Team Productivity</MetricLabel>
                  <div style={{ paddingRight: "40px" }}>118%</div>
                  <IndicatorCircle color="#4CAF50" />
                </MetricRow>
                
                {/* Team Productivity Chart - Conditionally rendered */}
                <div 
                  style={{ 
                    marginTop: "15px", 
                    backgroundColor: "white", 
                    padding: showTeamProductivityChart ? "10px" : "0", 
                    borderRadius: "6px",
                    transition: "all 0.3s ease",
                    maxHeight: showTeamProductivityChart ? "200px" : "0",
                    overflow: "hidden",
                    opacity: showTeamProductivityChart ? "1" : "0",
                    visibility: showTeamProductivityChart ? "visible" : "hidden"
                  }}
                >
                  <ResponsiveContainer width="100%" height={150}>
                    <BarChart
                      data={[
                        { week: "7", value: 170 },
                        { week: "8", value: 98 },
                        { week: "9", value: 151 },
                        { week: "10", value: 50 },
                        { week: "11", value: 118 }
                      ]}
                      margin={{ top: 20, right: 10, left: 10, bottom: 20 }}
                    >
                      <XAxis dataKey="week" tick={{ fontSize: 10 }} />
                      <YAxis 
                        domain={[0, 180]} 
                        tick={{ fontSize: 10 }} 
                        label={{ value: '%', angle: -90, position: 'insideLeft', style: { fontSize: 10, textAnchor: 'middle' } }}
                      />
                      <Tooltip />
                      <ReferenceLine y={85} stroke="#333" strokeWidth={1} />
                      <Bar dataKey="value" fill="#4CAF50">
                        {[
                          { week: "7", value: 170 },
                          { week: "8", value: 98 },
                          { week: "9", value: 151 },
                          { week: "10", value: 50 },
                          { week: "11", value: 118 }
                        ].map((entry, index) => (
                          <Cell 
                            key={`cell-${index}`} 
                            fill={entry.value < 85 ? "#F44336" : "#4CAF50"} 
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                  <div style={{ textAlign: "center", fontSize: "12px", marginTop: "5px" }}>Week</div>
                </div>
                

              </div>
            </div>
            
            {/* Compliance Column - Orange Theme */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ backgroundColor: "#FF9800", borderRadius: "8px 8px 0 0", padding: "15px", color: "white" }}>
                <ColumnTitle>Compliance</ColumnTitle>
                <ColumnSubtitle>Last Week</ColumnSubtitle>
              </div>
              <div style={{ backgroundColor: "#FFF3E0", borderRadius: "0 0 8px 8px", padding: "15px" }}>
                <MetricRow 
                  className={`clickable ${showTeamComplianceRateChart ? 'expanded' : ''}`}
                  style={{ backgroundColor: "white", position: "relative" }}
                  onClick={() => setShowTeamComplianceRateChart(!showTeamComplianceRateChart)}
                >
                  <MetricLabel>Compliance Rate</MetricLabel>
                  <div style={{ paddingRight: "40px" }}>92%</div>
                  <IndicatorCircle color="#F44336" />
                </MetricRow>
                
                {/* Compliance Rate Chart - Conditionally rendered */}
                <div 
                  style={{ 
                    marginTop: "15px", 
                    backgroundColor: "white", 
                    padding: showTeamComplianceRateChart ? "10px" : "0", 
                    borderRadius: "6px",
                    transition: "all 0.3s ease",
                    maxHeight: showTeamComplianceRateChart ? "200px" : "0",
                    overflow: "hidden",
                    opacity: showTeamComplianceRateChart ? "1" : "0",
                    visibility: showTeamComplianceRateChart ? "visible" : "hidden"
                  }}
                >
                  <ResponsiveContainer width="100%" height={150}>
                    <BarChart
                      data={[
                        { week: "7", value: 100 },
                        { week: "8", value: 100 },
                        { week: "9", value: 100 },
                        { week: "10", value: 100 },
                        { week: "11", value: 92 }
                      ]}
                      margin={{ top: 20, right: 10, left: 10, bottom: 20 }}
                    >
                      <XAxis dataKey="week" tick={{ fontSize: 10 }} />
                      <YAxis 
                        domain={[90, 100]} 
                        tick={{ fontSize: 10 }} 
                        label={{ value: '%', angle: -90, position: 'insideLeft', style: { fontSize: 10, textAnchor: 'middle' } }}
                      />
                      <Tooltip />
                      <ReferenceLine y={95} stroke="#333" strokeWidth={1} />
                      <Bar dataKey="value" fill="#4CAF50">
                        {[
                          { week: "7", value: 100 },
                          { week: "8", value: 100 },
                          { week: "9", value: 100 },
                          { week: "10", value: 100 },
                          { week: "11", value: 92 }
                        ].map((entry, index) => (
                          <Cell 
                            key={`cell-${index}`} 
                            fill={entry.value < 95 ? "#F44336" : "#4CAF50"} 
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                  <div style={{ textAlign: "center", fontSize: "12px", marginTop: "5px" }}>Week</div>
                </div>
              </div>
            </div>
            
            {/* Experience Column - Yellow Theme */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ backgroundColor: "#FFEB3B", borderRadius: "8px 8px 0 0", padding: "15px", color: "white" }}>
                <ColumnTitle>Experience</ColumnTitle>
                <ColumnSubtitle>Last Week</ColumnSubtitle>
              </div>
              <div style={{ backgroundColor: "#FFFDE7", borderRadius: "0 0 8px 8px", padding: "15px" }}>
                <MetricRow 
                  className={`clickable ${showTeamEscalatedComplaintsChart ? 'expanded' : ''}`}
                  style={{ backgroundColor: "white", position: "relative" }}
                  onClick={() => setShowTeamEscalatedComplaintsChart(!showTeamEscalatedComplaintsChart)}
                >
                  <MetricLabel>Escalated Complaints</MetricLabel>
                  <div style={{ paddingRight: "40px" }}>35%</div>
                  <IndicatorCircle color="#F44336" />
                </MetricRow>
                
                {/* Escalated Complaints Chart - Conditionally rendered */}
                <div 
                  style={{ 
                    marginTop: "15px", 
                    backgroundColor: "white", 
                    padding: showTeamEscalatedComplaintsChart ? "10px" : "0", 
                    borderRadius: "6px",
                    transition: "all 0.3s ease",
                    maxHeight: showTeamEscalatedComplaintsChart ? "200px" : "0",
                    overflow: "hidden",
                    opacity: showTeamEscalatedComplaintsChart ? "1" : "0",
                    visibility: showTeamEscalatedComplaintsChart ? "visible" : "hidden"
                  }}
                >
                  <ResponsiveContainer width="100%" height={150}>
                    <BarChart
                      data={[
                        { week: "7", value: 19 },
                        { week: "8", value: 25 },
                        { week: "9", value: 23 },
                        { week: "10", value: 32 },
                        { week: "11", value: 35 }
                      ]}
                      margin={{ top: 20, right: 10, left: 10, bottom: 20 }}
                    >
                      <XAxis dataKey="week" tick={{ fontSize: 10 }} />
                      <YAxis 
                        domain={[0, 40]} 
                        tick={{ fontSize: 10 }} 
                        label={{ value: '%', angle: -90, position: 'insideLeft', style: { fontSize: 10, textAnchor: 'middle' } }}
                      />
                      <Tooltip />
                      <ReferenceLine y={20} stroke="#333" strokeWidth={1} />
                      <Bar dataKey="value" fill="#4CAF50">
                        {[
                          { week: "7", value: 19 },
                          { week: "8", value: 25 },
                          { week: "9", value: 23 },
                          { week: "10", value: 32 },
                          { week: "11", value: 35 }
                        ].map((entry, index) => (
                          <Cell 
                            key={`cell-${index}`} 
                            fill={entry.value > 20 ? "#F44336" : "#4CAF50"} 
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                  <div style={{ textAlign: "center", fontSize: "12px", marginTop: "5px" }}>Week</div>
                </div>
              </div>
            </div>
          </div>
        </Card>

        <Card>
          <CardTitle>Actions</CardTitle>
          <ActionTable>
            <thead>
              <tr>
                <th>Description</th>
                <th>Date Assigned</th>
                <th>Team Member</th>
                <th>Related Metric</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {actions.map((action, index) => (
                <tr key={index}>
                  <td>{action.description}</td>
                  <td>{action.dateAssigned}</td>
                  <td>{action.teamMember}</td>
                  <td>
                    <FontAwesomeIcon icon={action.relatedMetric} />
                  </td>
                  <td>
                    <Button onClick={() => console.log("Modify action", index)}>
                      Modify
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </ActionTable>
          <AddActionButton onClick={addAction}>Add New Action</AddActionButton>
        </Card>

        <IndividualPerformance>
          <summary>Individual Performance</summary>
          <IndividualPerformanceTable>
            <thead>
              <tr>
                <TableHeader>Team Member</TableHeader>
                <TableHeader>
                  Productivity
                  <SmallPrint>yesterday</SmallPrint>
                </TableHeader>
                <TableHeader>
                  Productivity
                  <SmallPrint>week to date</SmallPrint>
                </TableHeader>
                <TableHeader>
                  Throughput
                  <SmallPrint>Avg Apps / 8 hours</SmallPrint>
                </TableHeader>
                <TableHeader>
                  Quality
                  <SmallPrint>month to date</SmallPrint>
                </TableHeader>
              </tr>
            </thead>
            <tbody>
              {individualPerformanceData.map((member, index) => (
                <tr key={index}>
                  <TableCell>{member.name}</TableCell>
                  <TableCell>
                    <MetricValue
                      color={getColorForMetric(member.productivityYesterday, {
                        high: 90,
                        low: 80,
                      })}
                    >
                      {member.productivityYesterday}%
                    </MetricValue>
                  </TableCell>
                  <TableCell>
                    <MetricValue
                      color={getColorForMetric(member.productivityWeek, {
                        high: 90,
                        low: 85,
                      })}
                    >
                      {member.productivityWeek}%
                    </MetricValue>
                  </TableCell>
                  <TableCell>
                    <MetricValue
                      color={getColorForMetric(member.throughput, {
                        high: 8,
                        low: 7,
                      })}
                    >
                      {member.throughput}
                    </MetricValue>
                  </TableCell>
                  <TableCell>
                    <MetricValue
                      color={getColorForMetric(member.quality, {
                        high: 95,
                        low: 90,
                      })}
                    >
                      {member.quality}%
                    </MetricValue>
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </IndividualPerformanceTable>
        </IndividualPerformance>
      </ViewContainer>



      {/* E2E Performance Metrics View */}
      <ViewContainer active={activeView === "e2ePerformance"}>
        <Card>
          <CardTitle>E2E Performance Metrics</CardTitle>
          <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "20px" }}>
            {/* Delivery Column - Blue Theme - Matches Turn Around Time row */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ backgroundColor: "#4285F4", borderRadius: "8px 8px 0 0", padding: "15px", color: "white" }}>
                <ColumnTitle>Delivery</ColumnTitle>
                <ColumnSubtitle>February 2025</ColumnSubtitle>
              </div>
              <div style={{ backgroundColor: "#E3F2FD", borderRadius: "0 0 8px 8px", padding: "15px" }}>
                <MetricRow style={{ backgroundColor: "white", position: "relative" }}>
                  <MetricLabel>Volume</MetricLabel>
                  <div style={{ paddingRight: "40px" }}>2390</div>
                  <IndicatorCircle color="white" />
                </MetricRow>
                
                <MetricRow style={{ backgroundColor: "white", position: "relative" }}>
                  <MetricLabel>Customer Service Level</MetricLabel>
                  <div style={{ paddingRight: "40px" }}>87%</div>
                  <IndicatorCircle color="#F44336" />
                </MetricRow>
                
                <MetricRow 
                  className={`clickable ${showTurnAroundTimeChart ? 'expanded' : ''}`}
                  style={{ backgroundColor: "white", position: "relative" }}
                  onClick={() => setShowTurnAroundTimeChart(!showTurnAroundTimeChart)}
                >
                  <MetricLabel>Turn Around Time</MetricLabel>
                  <div style={{ paddingRight: "40px" }}>5.2 Days</div>
                  <IndicatorCircle color="#F44336" />
                </MetricRow>
                
                {/* Turn Around Time Chart - Conditionally rendered */}
                {showTurnAroundTimeChart && (
                  <div 
                    style={{ 
                      marginTop: "15px", 
                      backgroundColor: "white", 
                      padding: "10px", 
                      borderRadius: "6px",
                      transition: "all 0.3s ease",
                      maxHeight: "200px",
                      overflow: "hidden"
                    }}
                  >
                    <ResponsiveContainer width="100%" height={150}>
                      <BarChart
                        data={[
                          { month: "Oct-24", value: 3.9 },
                          { month: "Nov-24", value: 4.6 },
                          { month: "Dec-24", value: 5.0 },
                          { month: "Jan-25", value: 4.9 },
                          { month: "Feb-25", value: 5.2 }
                        ]}
                        margin={{ top: 20, right: 10, left: 10, bottom: 20 }}
                      >
                        <XAxis dataKey="month" tick={{ fontSize: 10 }} />
                        <YAxis 
                          domain={[3, 6]} 
                          tick={{ fontSize: 10 }} 
                          label={{ value: 'Days', angle: -90, position: 'insideLeft', style: { fontSize: 10, textAnchor: 'middle' } }}
                        />
                        <Tooltip />
                        <ReferenceLine y={3.7} stroke="#333" strokeWidth={1} />
                        <Bar dataKey="value" fill="#4CAF50">
                          {[
                            { month: "Oct-24", value: 3.9 },
                            { month: "Nov-24", value: 4.6 },
                            { month: "Dec-24", value: 5.0 },
                            { month: "Jan-25", value: 4.9 },
                            { month: "Feb-25", value: 5.2 }
                          ].map((entry, index) => (
                            <Cell 
                              key={`cell-${index}`} 
                              fill={entry.value > 4.5 ? "#F44336" : "#4CAF50"} 
                            />
                          ))}
                        </Bar>
                        {/* Add value labels above each bar */}
                        {[
                          { month: "Oct-24", value: 3.9 },
                          { month: "Nov-24", value: 4.6 },
                          { month: "Dec-24", value: 5.0 },
                          { month: "Jan-25", value: 4.9 },
                          { month: "Feb-25", value: 5.2 }
                        ].map((entry, index) => (
                          <text
                            key={`label-${index}`}
                            x={index * 40 + 30}
                            y={100 - (entry.value - 3) * 30}
                            fill="#333"
                            textAnchor="middle"
                            fontSize={10}
                          >
                            {entry.value}
                          </text>
                        ))}
                      </BarChart>
                    </ResponsiveContainer>
                    <div style={{ textAlign: "center", fontSize: "12px", marginTop: "5px" }}>Month</div>
                  </div>
                )}
              </div>
            </div>
            
            {/* Cost Column - Purple Theme - Matches Rework rate row */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ backgroundColor: "#A142F4", borderRadius: "8px 8px 0 0", padding: "15px", color: "white" }}>
                <ColumnTitle>Cost</ColumnTitle>
                <ColumnSubtitle>February 2025</ColumnSubtitle>
              </div>
              <div style={{ backgroundColor: "#F3E5F5", borderRadius: "0 0 8px 8px", padding: "15px" }}>
                <MetricRow style={{ backgroundColor: "white", position: "relative" }}>
                  <MetricLabel>Cost to Serve</MetricLabel>
                  <div style={{ paddingRight: "40px" }}>$19.5</div>
                  <IndicatorCircle color="#4CAF50" />
                </MetricRow>
                
                <MetricRow 
                  className={`clickable ${showReworkRateChart ? 'expanded' : ''}`}
                  style={{ backgroundColor: "white", position: "relative" }}
                  onClick={() => setShowReworkRateChart(!showReworkRateChart)}
                >
                  <MetricLabel>Rework rate</MetricLabel>
                  <div style={{ paddingRight: "40px" }}>49%</div>
                  <IndicatorCircle color="#F44336" />
                </MetricRow>
                
                {/* Rework Rate Chart - Conditionally rendered */}
                {showReworkRateChart && (
                  <div 
                    style={{ 
                      marginTop: "15px", 
                      marginBottom: "15px", 
                      backgroundColor: "white", 
                      padding: "10px", 
                      borderRadius: "6px",
                      transition: "all 0.3s ease",
                      maxHeight: "200px",
                      overflow: "hidden"
                    }}
                  >
                    <ResponsiveContainer width="100%" height={150}>
                      <BarChart
                        data={[
                          { month: "Oct-24", value: 35 },
                          { month: "Nov-24", value: 42 },
                          { month: "Dec-24", value: 45 },
                          { month: "Jan-25", value: 47 },
                          { month: "Feb-25", value: 49 }
                        ]}
                        margin={{ top: 20, right: 10, left: 10, bottom: 20 }}
                      >
                        <XAxis dataKey="month" tick={{ fontSize: 10 }} />
                        <YAxis 
                          domain={[30, 50]} 
                          tick={{ fontSize: 10 }} 
                          label={{ value: '%', angle: -90, position: 'insideLeft', style: { fontSize: 10, textAnchor: 'middle' } }}
                        />
                        <Tooltip />
                        <ReferenceLine y={40} stroke="#333" strokeWidth={1} />
                        <Bar dataKey="value" fill="#4CAF50">
                          {[
                            { month: "Oct-24", value: 35 },
                            { month: "Nov-24", value: 42 },
                            { month: "Dec-24", value: 45 },
                            { month: "Jan-25", value: 47 },
                            { month: "Feb-25", value: 49 }
                          ].map((entry, index) => (
                            <Cell 
                              key={`cell-${index}`} 
                              fill={entry.value > 40 ? "#F44336" : "#4CAF50"} 
                            />
                          ))}
                        </Bar>
                        {/* Add value labels above each bar */}
                        {[
                          { month: "Oct-24", value: 35 },
                          { month: "Nov-24", value: 42 },
                          { month: "Dec-24", value: 45 },
                          { month: "Jan-25", value: 47 },
                          { month: "Feb-25", value: 49 }
                        ].map((entry, index) => (
                          <text
                            key={`label-${index}`}
                            x={index * 40 + 30}
                            y={100 - (entry.value - 30) * 5}
                            fill="#333"
                            textAnchor="middle"
                            fontSize={10}
                          >
                            {entry.value}%
                          </text>
                        ))}
                      </BarChart>
                    </ResponsiveContainer>
                    <div style={{ textAlign: "center", fontSize: "12px", marginTop: "5px" }}>Month</div>
                  </div>
                )}
                
                <MetricRow style={{ backgroundColor: "white", position: "relative" }}>
                  <MetricLabel>Throughput</MetricLabel>
                  <div style={{ paddingRight: "40px" }}>5.2</div>
                  <IndicatorCircle color="#4CAF50" />
                </MetricRow>
              </div>
            </div>
            
            {/* Compliance Column - Orange Theme - Matches Cost to Serve row */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ backgroundColor: "#FF9800", borderRadius: "8px 8px 0 0", padding: "15px", color: "white" }}>
                <ColumnTitle>Compliance</ColumnTitle>
                <ColumnSubtitle>February 2025</ColumnSubtitle>
              </div>
              <div style={{ backgroundColor: "#FFF3E0", borderRadius: "0 0 8px 8px", padding: "15px" }}>
                <MetricRow style={{ backgroundColor: "white", position: "relative" }}>
                  <MetricLabel>Compliance Rate</MetricLabel>
                  <div style={{ paddingRight: "40px" }}>98.1%</div>
                  <IndicatorCircle color="#FFC107" />
                </MetricRow>
                
                <MetricRow style={{ backgroundColor: "white", position: "relative" }}>
                  <MetricLabel>QA Pass rate</MetricLabel>
                  <div style={{ paddingRight: "40px" }}>73%</div>
                  <IndicatorCircle color="#F44336" />
                </MetricRow>
              </div>
            </div>
            
            {/* Experience Column - Yellow Theme */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ backgroundColor: "#FFEB3B", borderRadius: "8px 8px 0 0", padding: "15px", color: "white" }}>
                <ColumnTitle>Experience</ColumnTitle>
                <ColumnSubtitle>February 2025</ColumnSubtitle>
              </div>
              <div style={{ backgroundColor: "#FFFDE7", borderRadius: "0 0 8px 8px", padding: "15px" }}>
                <MetricRow style={{ backgroundColor: "white", position: "relative" }}>
                  <MetricLabel>Non escalated Complaints</MetricLabel>
                  <div style={{ paddingRight: "40px" }}>27%</div>
                  <IndicatorCircle color="#F44336" />
                </MetricRow>
                
                <MetricRow style={{ backgroundColor: "white", position: "relative" }}>
                  <MetricLabel>NPS</MetricLabel>
                  <div style={{ paddingRight: "40px" }}>###%</div>
                  <IndicatorCircle color="white" />
                </MetricRow>
              </div>
            </div>
          </div>
        </Card>
      </ViewContainer>

      {/* E2E Value Chain View */}
      <ViewContainer active={activeView === "e2eValueChain"}>
        <Card>
          <CardTitle>Manager View - E2E Value Chain</CardTitle>
          <div style={{ fontSize: "16px", fontStyle: "italic", marginBottom: "20px" }}>February 2025</div>
          
          {/* Value Chain Stages */}
          <div style={{ display: "grid", gridTemplateColumns: "repeat(5, 1fr)", gap: "15px", marginBottom: "20px" }}>
            {valueChainStages.map((stage, index) => (
              <StageColumn key={index}>
                <StageTitle>{stage.name}</StageTitle>
                <StageMetrics>
                  <div>
                    <FontAwesomeIcon icon={faUserTie} /> {stage.staffCount}
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faClipboardCheck} /> {stage.documentCount}
                  </div>
                </StageMetrics>
                <div style={{ marginTop: "10px" }}>
                  <FontAwesomeIcon icon={faChartLine} /> {stage.timeMinutes} min
                </div>
              </StageColumn>
            ))}
          </div>
          
          {/* Metrics Bars */}
          <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            {/* Turn Around Time Bar */}
            <div style={{ display: "flex", alignItems: "stretch" }}>
              <div style={{ width: "20px", backgroundColor: "#4285F4", borderRadius: "4px 0 0 4px" }}></div>
              <MetricBar backgroundColor="#E3F2FD" style={{ flex: 1, borderRadius: "0 4px 4px 0" }}>
                <MetricBarTitle>
                  Turn Around Time <span style={{ fontWeight: "normal" }}>({turnAroundTimeData.total} Days)</span>
                </MetricBarTitle>
                <div style={{ position: "relative", display: "flex", flex: 1 }}>
                  {turnAroundTimeData.stages.map((value, index) => (
                    <MetricBarSegment key={index}>
                      {turnAroundTimeData.warnings[index] && <WarningTriangle />}
                      {value} Days
                    </MetricBarSegment>
                  ))}
                </div>
              </MetricBar>
            </div>
            
            {/* Rework Rate Bar */}
            <div style={{ display: "flex", alignItems: "stretch" }}>
              <div style={{ width: "20px", backgroundColor: "#A142F4", borderRadius: "4px 0 0 4px" }}></div>
              <MetricBar backgroundColor="#F3E5F5" style={{ flex: 1, borderRadius: "0 4px 4px 0" }}>
                <MetricBarTitle>
                  Rework rate <span style={{ fontWeight: "normal" }}>({reworkRateData.total}%)</span>
                </MetricBarTitle>
                <div style={{ position: "relative", display: "flex", flex: 1 }}>
                  {reworkRateData.stages.map((value, index) => (
                    <MetricBarSegment key={index}>
                      {reworkRateData.warnings[index] && <WarningTriangle />}
                      {value}%
                    </MetricBarSegment>
                  ))}
                </div>
              </MetricBar>
            </div>
            
            {/* Cost to Serve Bar - Part of the same purple section as Rework rate */}
            <div style={{ display: "flex", alignItems: "stretch", marginTop: "0" }}>
              <div style={{ width: "20px", backgroundColor: "#A142F4", borderRadius: "4px 0 0 4px" }}></div>
              <MetricBar backgroundColor="#F3E5F5" style={{ flex: 1, borderRadius: "0 4px 4px 0" }}>
                <MetricBarTitle>
                  Cost to Serve <span style={{ fontWeight: "normal" }}>${costToServeData.total}</span>
                </MetricBarTitle>
              </MetricBar>
            </div>
            
            <div style={{ fontSize: "12px", color: "#666", marginTop: "5px", marginLeft: "25px" }}>
              (i) Rework values will add up to the total by design
            </div>
            
            {/* Empty Orange Box */}
            <div style={{ display: "flex", alignItems: "stretch", marginTop: "15px" }}>
              <div style={{ width: "20px", backgroundColor: "#FF9800", borderRadius: "4px 0 0 4px" }}></div>
              <MetricBar backgroundColor="#FFF3E0" style={{ flex: 1, borderRadius: "0 4px 4px 0" }}>
              </MetricBar>
            </div>
            
            {/* Empty Yellow Box */}
            <div style={{ display: "flex", alignItems: "stretch", marginTop: "15px" }}>
              <div style={{ width: "20px", backgroundColor: "#FFEB3B", borderRadius: "4px 0 0 4px" }}></div>
              <MetricBar backgroundColor="#FFFDE7" style={{ flex: 1, borderRadius: "0 4px 4px 0" }}>
              </MetricBar>
            </div>
          </div>
        </Card>
      </ViewContainer>

    </DashboardContainer>
  );
};

export default Dashboard;
